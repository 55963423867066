import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { formatEthereumAddress } from "../Helpers";

function Header({ user, open }) {
    const location = useLocation();

    useEffect(() => {
        const currentPage = location.pathname;
        const bodyClass = getBodyClass(currentPage);

        // Ensure the default class is set initially if not already present
        if (!document.body.classList.contains("leaderboard-bg")) {
            document.body.classList.add("leaderboard-bg");
        }

        // Update the body class according to the route, avoiding adding if it's already set
        updateBodyClass(bodyClass);

        // Cleanup function to manage classes on route changes
        return () => {
            document.body.classList.remove(
                "leaderboard-bg",
                "studio-bg",
                "referrals-bg",
                "quest-bg"
            );
            document.body.classList.add(bodyClass); // Maintain the current class on unmount if needed
        };
    }, [location]); // Dependency on location to rerun when route changes

    function getBodyClass(pathname) {
        switch (pathname) {
            case "/":
                return "leaderboard-bg";
            case "/studio":
                return "studio-bg";
            case "/referrals":
                return "referrals-bg";
            case "/quests/telegram":
            case "/quests/user":
                return "quest-bg";
            default:
                return "leaderboard-bg"; // Default to leaderboard if no route matched
        }
    }

    function updateBodyClass(newClass) {
        const classesToRemove = [
            "leaderboard-bg",
            "studio-bg",
            "referrals-bg",
            "quest-bg",
        ];
        classesToRemove.forEach((c) => {
            if (c !== newClass && document.body.classList.contains(c)) {
                document.body.classList.remove(c);
            }
        });

        if (!document.body.classList.contains(newClass)) {
            document.body.classList.add(newClass);
        }
    }

    return (
        <div className="container header">
            <div className="row d-flex justify-content-between align-items-center">
                <div className="col-12 col-md-2 text-center text-md-start">
                    <NavLink to="/">
                        <img
                            src="/build/img/logo.svg"
                            alt="Lympid"
                            className="logo"
                        />
                    </NavLink>
                </div>
                <div className="col-12 col-md-8 mt-4 mt-md-0 text-center">
                    <nav>
                        <NavLink to="/" activeclassname="active">
                            Leaderboard
                        </NavLink>
                        <NavLink to="/studio" activeclassname="active">
                            Studio
                        </NavLink>
                        {/* <NavLink to="/referrals" activeclassname="active">
                            Referrals
                        </NavLink> */}
                    </nav>
                </div>
                <div className="col-12 col-md-2 text-center text-md-end">
                    {user ? (
                        <button
                            className="wallet-button-connected"
                            onClick={() => {
                                open();
                            }}
                        >
                            <i className="bi bi-wallet2"></i>{" "}
                            {formatEthereumAddress(user.wallet_address)}
                        </button>
                    ) : (
                        <button
                            className="wallet-button"
                            onClick={() => {
                                open();
                            }}
                        >
                            Connect Wallet
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Header;
